import {countryList} from '../xcl_modules/countries';

const CountriesDropDown = () => {
    const countries = countryList();
    return (
      <>
        {countries.map((country, i) => (
            <option key={"cn" + i} value={country.alpha2Code} >{country.alpha2Code} - {country.englishShortName}</option>
        )) }
      </>
    );
}

export default CountriesDropDown;