import ScrollToTop from '../components/ScrollToTop';
import linksStyle from '../xcl_modules/linksStyle';
import { List } from '../components/ListLinks';
import validate from '../xcl_modules/validate';
import chainlink from '../css/icons/icon-chain.png';
import chainlinkButtonGraphic from '../css/icons/icon-chain-btn.png';
import chainlinkButtonGraphicDisabled from '../css/icons/icon-chain-wire.png';
import { useState, useEffect  } from 'react';
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import getTokenData from '../xcl_modules/tokenData';
import Swal from 'sweetalert2';

const chainlinkAlt = "Links in a chain";

const Links = () => {

  //const [changed, setChanged] = useState(false);
  const [profiles, setProfiles] = useState( 0 );
  const [cap, setCap] = useState( 1 );
  const [len, setLen] = useState( 0 );
  const [ready, setReady] = useState( false );
  const [navOpen] = useOutletContext();

  const location = useNavigate();

  useEffect(() => {
    validate();

    const getData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BE+'/linkprofiles', {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 
                Authorization: `Bearer ${getTokenData().accessToken}`}
        });
        const result = await response.json();
        if (result.code === 202) {
            // there are no profiles :(
            result.list = [ { ID:0, listEmpty: "message" } ];
        } else {
          setProfiles(result.list.length);
        }
        
      } catch {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "System Error :O  Please Login again.",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastCongratsOn'
          },
          hideClass: {
              popup: 'toastCongratsOff'
          }
        });
      }
    }
    getData();
  }, []);

  const doSelectRow = (rowID) => {
    localStorage.setItem("editLinkID", rowID);
    location("/linkname");
  } 

  const doSetCapAndLen = (thisCap, thisLen) => {
    setCap(thisCap);
    setLen(thisLen);
    setReady(true);
  }

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <style>{linksStyle}</style>
      <ScrollToTop/>
      <h1 id="contentstarts">
        <img src={chainlink} alt={chainlinkAlt} className='iconInline' />
        Learning Links 
        <div title="Patent Statement" className='patentMessage listpage'>{process.env.REACT_APP_PATENT_MESSAGE}<br/><span style={{ fontSize: "0.75rem" }}>{process.env.REACT_APP_PATENT_NUMBER}</span></div>
      </h1>
      <Link to={(profiles>0 && cap>len ? '/linknew' : '')} className={'createNewBtn links '+(profiles>0 && cap>len ? '' : 'disabled')} style={(!ready) ? { display:"none"} : { backgroundImage: (cap<=len)? `url('${chainlinkButtonGraphicDisabled}')` : `url('${chainlinkButtonGraphic}')` }}>
      {(cap<=len) ? (
          <>
            You've reached your Learning Link limit
          </>          
        ) : (
          <>
            Create a New Learning Link
          </>
        )}
      </Link>
      <h4>
        Create and manage your learning links here. Ensure you've created a brand template first. You'll be applying it to your link. 
      </h4>
      <List 
        emptyheading="You haven't created a Learning Link yet!"
        createprofilelink="Create a Brand Template"
        emptyinstruction=" before you create your learning link. You'll need to apply a brand template to your learning link during set-up!"
        linklabel="Create a Learning Link"
        showkeys='["brand_logo","profilename","name","published","filetype","created","modified"]'
        tableHeaders='["Template Logo", "Brand Template", "Link Name", "Live", "Type", "Created On", "Last Modified"]'
        sortablekeys='["name","created","modified"]'
        sortablekeyDirs='["","","az","","","za","za"]'
        profiles={profiles}
        linkto="/linknew"
        linktoAlt="/xcl/profiles"
        setCapAndLen={ doSetCapAndLen }
        doSelectRow={ doSelectRow }
        />
    </div>
    
  );
};

export default Links;