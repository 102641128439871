import ScrollToTop from '../components/ScrollToTop';
import { Link, useOutletContext } from "react-router-dom";
import validate from '../xcl_modules/validate';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import accountPasswordFeature from '../img/account-password-feature.png';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import iconSave from '../css/icons/icon-save.png';
import ConfirmPasswordChange from '../components/ConfirmPasswordChange';

const AccountPassword = () => {
    const [inputs, setInputs] = useState({});
    const [successMessage, setSuccess] = useState("");
    const [passwordMismatch, setpasswordMismatch] = useState("");
    const [confPass, setConfPass] = useState(false);

    const [navOpen] = useOutletContext();

    //const nav = useNavigate();

    useEffect(() => {

        validate();

    }, []);
  
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }
    const handleCheckPassRetype = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        if (event.target.value === inputs.newpassword) {
            setpasswordMismatch("goodentry");
        } else {
            setpasswordMismatch("badentry");
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (inputs.newpassword && inputs.password) {
            if (inputs.newpassword === inputs.newpassword_retype) {
                // send this server side to send the email from the forgot password path
                const fbRes = await fetch(process.env.REACT_APP_BE+'/updatepassword', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`},
                    body: JSON.stringify( { "password": inputs.password, "newpass": inputs.newpassword } )
                })
    
                const fbData = await fbRes.json();
    
                if (fbData.code === 1) {
                    // success
                    setSuccess("Your password has been reset successfully!");
                    Swal.fire({
                        position: "top",
                        iconHtml:  '<div class="positoast"></div>',
                        title: "Your Password has been successfully updated!",
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        timerProgressBar: true,
                        showClass: {
                            popup: 'toastCongratsOn'
                        },
                        hideClass: {
                            popup: 'toastCongratsOff'
                        }
                    });
                } else if (fbData.code === 0) {
                    setSuccess("");
                    Swal.fire({
                        position: "top",
                        iconHtml:  '<div class="correctitoast"></div>',
                        title: fbData.message,
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        timerProgressBar: true,
                        showClass: {
                            popup: 'toastWarningOn'
                        },
                        hideClass: {
                            popup: 'toastWarningOff'
                        }
                    });
                }
    
            } else {
                setSuccess("");
                Swal.fire({
                    position: "top",
                    iconHtml:  '<div class="correctitoast"></div>',
                    title: "Please make sure you've re-entered your password correctly",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true,
                    showClass: {
                        popup: 'toastWarningOn'
                    },
                    hideClass: {
                        popup: 'toastWarningOff'
                    }
                });
    
            }

        } else {
            Swal.fire({
                position: "top",
                iconHtml:  '<div class="correctitoast"></div>',
                title: "Please fill out all fields before saving",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastWarningOn'
                },
                hideClass: {
                    popup: 'toastWarningOff'
            }
            });
    
        }
    }

    const doForgotPassword = () => {
        setConfPass(true);
        const userData = getUserData();
        // do the forgot password BE route
        fetch(process.env.REACT_APP_BE+'/forgotPassword', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify( { "username": userData.username } )
        })
        //console.log("Username: " + userData.username);
    }

    const closeForgotPassword = () => {
        setConfPass(false);
    }

    return (
        <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
        <ScrollToTop/>
        <h1 id="contentstarts">My Account</h1>
        <div className='accountsContainer'>
            <div className="account-tabs">
            <div className="account-tab">
                <Link to="/xcl/accountprofile" className="at-label">Account Settings</Link>
            </div>
            <div className="account-tab active">
                <Link to="/xcl/accountpassword" className="at-label selected">Security & Password</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountplan" className="at-label">My Plan</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountbilling" className="at-label">Billing History</Link>
            </div>
            </div>
            <div className="account-tiles" style={{ borderRadius: "0px 8px 8px 8px" }}>

                <div className="account-smaller-half-tile">
                    <img src={accountPasswordFeature} alt="Account Password Rules"/>
                    <h4>Password must be a minimum of 6 characters and contain two of the following:</h4>
                    <ul>
                        <li>Upper case and lower case letter</li>
                        <li>Numeric character (0-9)</li>
                        <li>Special character (!, @, #, $, etc.)</li>
                    </ul>
                </div>

                <div className="account-larger-half-tile">
                    <div className='account-password-block'>
                        <h3>Change Password</h3>
                        <label htmlFor="password">Type your current password</label>
                        <input type="password" 
                            value={inputs.password || ""} 
                            maxLength="70" 
                            name="password" 
                            placeholder="Current password" 
                            onChange={handleChange}
                            autoFocus
                            disabled={ (successMessage==="") ? false : true }></input>
                        <label htmlFor="newpassword">Enter your new password</label>
                        <input type="password" 
                            value={inputs.newpassword || ""} 
                            maxLength="70" 
                            name="newpassword" 
                            placeholder="New password" 
                            onChange={handleChange}
                            autoFocus
                            disabled={ (successMessage==="") ? false : true }></input>
                        <label htmlFor="newpassword_retype">Retype your new password</label>
                        <input type="password" className={passwordMismatch}
                            maxLength="70" 
                            name="newpassword_retype" 
                            placeholder="Retype password" 
                            onChange={handleCheckPassRetype}
                            disabled={ (successMessage==="") ? false : true }></input>
                        <div className="account-lower-controls">
                            <Link onClick={doForgotPassword}>Forgot Password?</Link>
                            <input type="submit" name='savechangedpassword' value="Save Password " onClick={handleSubmit} style={{ backgroundImage: `url('${iconSave}')`}}></input>
                        </div>
                        <ConfirmPasswordChange open={confPass} onClose={closeForgotPassword} />
                    </div>
                </div>

            </div>

        </div>


        </div>
    );
};

export default AccountPassword;