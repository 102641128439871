import ScrollToTop from '../components/ScrollToTop';
import { Link, useOutletContext } from "react-router-dom";
import validate from '../xcl_modules/validate';
import { useEffect, useState } from 'react';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import Swal from 'sweetalert2';
import UpgradeOptions from '../components/UpgradeOptions';

const AccountPlan = () => {
    const [navOpen] = useOutletContext();
    const [account, setAccount] = useState({});
    const [bytesused, setBytesused] = useState(0);
    const [subscription, setSubscription] = useState({});
    const [plan, setPlan] = useState({});
    const [popupOpen, setPopupOpen] = useState(false);
    const [monthlyURL, setMonthlyURL] = useState("");
    const [yearlyURL, setYearlyURL] = useState("");
    const [monthlyUpgradeURL, setMonthlyUpgradeURL] = useState("");
    const [yearlyUpgradeURL, setYearlyUpgradeURL] = useState("");
    const [upgradeable, setUpgradeable] = useState(false);
  
    useEffect(() => {

        validate();

        // new endpoint for getting single sub information
        const userData = getUserData();

        const getData = async () => {
            try {
          
                const response = await fetch(process.env.REACT_APP_BE+`/subscription/${userData.account}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                      Authorization: `Bearer ${getTokenData().accessToken}`}
                });
        
                const result = await response.json();
        
                if (result.status === "success") {
                    setAccount(result.data);
                    setBytesused(result.bytesUsed);
                    setSubscription(result.sub);
                    setPlan(result.plan);
                }

                const getPortal = async (customerID, subsID) => {
                    try {
                        
                        const response2 = await fetch(process.env.REACT_APP_BE+`/customerportal`, {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json', 
                            Authorization: `Bearer ${getTokenData().accessToken}`},
                            body: JSON.stringify( { cb_cust_id: customerID, cb_subs_id: subsID } )
                        });
                
                        const result2 = await response2.json();
                
                        if (result2.status === "success") {
                            // we have a portal!  or do we?
                            //setPortalSession(result.portal);
                            if (result.plan.name) {
                                if (result.plan.name.indexOf("Build Pro") > -1) {
                                    setUpgradeable(false);
                                } else {
                                    setUpgradeable(true);
                                }
                            }
                           setMonthlyURL(result2.montly_sub.url);
                            setYearlyURL(result2.yearly_sub.url);
                            setMonthlyUpgradeURL(result2.montly_upgrade.url);
                            setYearlyUpgradeURL(result2.yearly_upgrade.url);
                        }
                
                    } catch {
                        console.log("error getting portal data - see tools");
                    }
                }
            
                getPortal(result.data.cb_cust_id, result.data.cb_subs_id);
        
        
            } catch {
                console.log("error getting data - see tools");
            }
        }
        
        getData();
        
  

    }, []);

    const doCancelPendingCancellation = async () => {
        //console.log("cancel pending cancellation");
        try {
          
            const response = await fetch(process.env.REACT_APP_BE+`/undoscheduledcancellation`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 
                Authorization: `Bearer ${getTokenData().accessToken}`},
                body: JSON.stringify( { subscriptionID: subscription.id } )
        });
    
        const result = await response.json();
    
        if (result.status === "success") {
            //console.log(result.message);
            setSubscription(result.sub);
            if (result.sub.status) {
              if (result.sub.status === "active" || result.sub.status === "in_trial") {
                Swal.fire({
                  position: "top",
                  iconHtml:  '<div class="positoast"></div>',
                  title: "Your subscription is active once again!",
                  showConfirmButton: false,
                  timer: 1500,
                  toast: true,
                  timerProgressBar: true,
                  showClass: {
                      popup: 'toastCongratsOn'
                  },
                  hideClass: {
                      popup: 'toastCongratsOff'
                  }
                });
            } else {
                Swal.fire({
                  position: "top",
                  iconHtml:  '<div class="correctitoast"></div>',
                  title: "Sadly, we couldn't undo the scheduled cancellation, please contact Build Capable using the <strong>Help and Support</strong> screen",
                  showConfirmButton: false,
                  timer: 5000,
                  toast: true,
                  timerProgressBar: true,
                  showClass: {
                      popup: 'toastWarningOn'
                    },
                  hideClass: {
                      popup: 'toastWarningOff'
                  }
                });
              }
            }
    
        } else {
            Swal.fire({
              position: "top",
              iconHtml:  '<div class="correctitoast"></div>',
              title: "Sadly, we couldn't undo the scheduled cancellation, please contact Build Capable using the <strong>Help and Support</strong> screen",
              showConfirmButton: false,
              timer: 5000,
              toast: true,
              timerProgressBar: true,
              showClass: {
                  popup: 'toastWarningOn'
                },
              hideClass: {
                  popup: 'toastWarningOff'
              }
            });
          }
    
        } catch {
            console.log("error setting data - see tools");
        }
    }

    const optionsPopupOpen = () => {
        setPopupOpen(true);
    }

    const optionsPopupClose = () => {
        setPopupOpen(false);
    }
    
  
    // temp values
    const profileLimit          = (account.link_profile_cap | 0);
    const profilesUsed          = (account.link_profiles_used | 0);
    const linkLimit             = (account.link_cap | 0);
    const linksUsed             = (account.links_used | 0);
    const storageAvail          = (account.storage | 0);
    const storageUsed           = (bytesused | 0) / 1024 / 1024; // convert to MB
    const upcomingInvoiceDateUTC= (subscription.next_billing_at | "Jan/15/25");
    const upcomingInvoiceDate   = new Date(upcomingInvoiceDateUTC * 1000).toLocaleDateString();
    const planPrice             = (plan.price | "0") / 100;
    const upcomingInvoiceAmount = (plan.price | "0") / 100;
    const planName              = plan.external_name;
    const planfreq              = plan.period_unit;
    const plandesc              = plan.description;

    return (
        <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
        <ScrollToTop/>
        <h1 id="contentstarts">My Account</h1>
        <div className='accountsContainer'>
            <div className="account-tabs">
            <div className="account-tab">
                <Link to="/xcl/accountprofile" className="at-label">Account Settings</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountpassword" className="at-label">Security & Password</Link>
            </div>
            <div className="account-tab active">
                <Link to="/xcl/accountplan" className="at-label selected">My Plan</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountbilling" className="at-label">Billing History</Link>
            </div>
            </div>
            <div className="account-tiles" style={{ borderRadius: "0px 8px 8px 8px" }}>

                <div className="account-smaller-half-tile" style={(account.ID) ? {display: "inline-block"} : {display:"none"}}>
                    <div className="account-half-content">
                        <Link className='acUpgrade' onClick={optionsPopupOpen} style={(upgradeable) ? { display: "inline" } : { display: "none" }}>Upgrade Plan</Link>
                        <UpgradeOptions 
                            open={popupOpen}
                            number={0} 
                            currentPlan={planName || ""}
                            currMonthlyURL={monthlyURL}
                            currYearlyURL={yearlyURL}
                            monthlyURL={monthlyUpgradeURL}
                            yearlyURL={yearlyUpgradeURL}
                            onClose={optionsPopupClose} />
                        <h4>My Plan</h4>
                        <div className='accountDashBlock internal'>
                            <h5>Upcoming Invoice</h5>
                            {(subscription.status === "in_trial" || subscription.status === "active") ? (
                                <>
                                    <div className='accountDashContent' style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className="upcoming-date">Date: {upcomingInvoiceDate}</div>
                                        <div className="upcoming-total">Total: ${upcomingInvoiceAmount}</div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    { (subscription.status === "non_renewing") ? (
                                        <div className='accountDashContent warning'>
                                            <p><strong>Cancellation pending</strong>. Your subscription was cancelled on {new Date(subscription.cancel_schedule_created_at * 1000).toLocaleDateString()}. 
                                            You can keep using XCL until {new Date(subscription.cancelled_at * 1000).toLocaleDateString()}.</p>
                                            <p>If you would like to restore your subscription, please cancel the pending cancellation.</p>
                                            <p><input type="button" value="Cancel Pending Cancellation" onClick={doCancelPendingCancellation} /></p>
                                        </div>
                                    ) : (
                                        <div className='accountDashContent warning'>
                                            <p><strong>Subscription Cancelled</strong>. Your subscription was cancelled on {new Date(subscription.cancel_schedule_created_at * 1000).toLocaleDateString()}. 
                                            You cannot use any features of XCL and your XCL Links will not function. If you would like to reactivate your subscription, please contact Build Capable using the <strong>Help and Support</strong> screen.</p>
                                            <p>Your account, data, and files will be deleted on {new Date((subscription.cancel_schedule_created_at + 2592000) * 1000).toLocaleDateString()}.</p>
                                        </div>
                                    )}
                                </>
                            )

                            }
                        </div>

                        { (subscription.status === "cancelled") ? (
                            <>
                            </>
                        ) : (
                            <>
                                <div className='accountDashBlock internal'>
                                    <h5>Brand Templates</h5>
                                    <div className='accountDashContent'>
                                        <div className='accountDashLimit'>{profileLimit} Template{(profileLimit===1) ? '' : 's'}</div>
                                        <div>
                                            <div className='acProgressBg'>
                                            <div className={ ((profilesUsed/profileLimit>0.75) ? 'acProgress warn' : 'acProgress') } style={ {width: ((profilesUsed/profileLimit)*100)+"%" } }></div>
                                            </div>
                                        </div>
                                        <div className='acLegend'>
                                            <div className='acLegendPart'>
                                            <div className={ ((profilesUsed/profileLimit>0.75) ? 'acProgLegend warn' : 'acProgLegend') }></div>
                                            {profilesUsed} Used
                                            </div>
                                            <div className='acLegendPart'>
                                            <div className='acRemLegend'></div>
                                            {profileLimit-profilesUsed} Remaining
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='accountDashBlock internal'>
                                    <h5>Learning Links</h5>
                                    <div className='accountDashContent'>
                                        <div className='accountDashLimit'>{linkLimit} Link{(linkLimit===1) ? '' : 's'}</div>
                                        <div className='acProgressBg'>
                                            <div className={ ((linksUsed/linkLimit>0.75) ? 'acProgress warn' : 'acProgress') } style={ {width: ((linksUsed/linkLimit)*100)+"%" } }></div>
                                        </div>
                                        <div className='acLegend'>
                                            <div className='acLegendPart'>
                                            <div className={ ((linksUsed/linkLimit>0.75) ? 'acProgLegend warn' : 'acProgLegend') }></div>
                                            {linksUsed} Used
                                            </div>
                                            <div className='acLegendPart'>
                                            <div className='acRemLegend'></div>
                                            {linkLimit-linksUsed} Remaining
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='accountDashBlock internal'>
                                    <h5>Storage</h5>
                                    <div className='accountDashContent'>
                                        <div className='accountDashLimit'>{(storageAvail>1000) ? (storageAvail / 1024) + ' GB' : storageAvail + ' MB'}</div>
                                        <div className='acProgressBg'>
                                            <div className={ ((storageUsed/storageAvail>0.75) ? 'acProgress warn' : 'acProgress') } style={ {width: ((storageUsed/storageAvail)*100)+"%" } }></div>
                                        </div>
                                        <div className='acLegend'>
                                            <div className='acLegendPart'>
                                            <div className={ ((storageUsed/storageAvail>0.75) ? 'acProgLegend warn' : 'acProgLegend') }></div>
                                            {(storageUsed>1000) ? Math.round((storageUsed / 1024)) + ' GB' : Math.round(storageUsed) + ' MB'} used
                                            </div>
                                            <div className='acLegendPart'>
                                            <div className='acRemLegend'></div>
                                            {((storageAvail-storageUsed)>1000) ? Math.round(((storageAvail-storageUsed) / 1024)) + ' GB' : Math.round((storageAvail-storageUsed)) + ' MB'} available
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                </div>

                <div className="account-larger-half-tile" style={(plan.status) ? {display: "inline-block"} : {display:"none"}}>
                    <div className="account-half-content">
                        <h4>Plan Details</h4>
                        <div className="account-plan-details-header">
                        { (subscription.status === "cancelled") ? (
                            <>
                                <div>
                                    <h4>No Active Subscription</h4>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <h4>{planName}</h4> {(subscription.status === "in_trial") ? '(In Trial)' : ''}
                                </div>
                                <div>
                                    ${planPrice} / {planfreq}
                                </div>
                            </>
                        )}
                        </div>
                        <div className="account-plan-details-body">
                            <p>{plandesc}</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>


        </div>
    );
};

export default AccountPlan;