import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import iconUpgrade from '../css/icons/icon-congrats-purple.png';

const UpgradeOptions = (props) => {
    
    const buildMonthlyUrl = props.currMonthlyURL;
    const buildYearlyUrl = props.currYearlyURL;
    const buildProMonthlyUrl = props.monthlyURL;
    const buildProYearlyUrl = props.yearlyURL;
    const currentPlan = (props.currentPlan.indexOf("Build Pro") >= 0) ? "Build Pro" : "Build";
    const currentFreq = (props.currentPlan.indexOf("Yearly") >= 0) ? `${currentPlan}_yearly` : `${currentPlan}_monthly`;

    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <>
                <div className='upgradeIcon' style={{ backgroundImage: `url('${iconUpgrade}')` }}></div>
                <div className='modalContent'>
                    <h2>Upgrade Options</h2>
                    <div style={ (currentPlan==="Build") ? { } : { display:"none" } }>
                        <p><strong>Build</strong> plan.</p>
                        <ul>
                            <li style={{ color: "#64748B" }}>Unlimited Learners</li>
                            <li style={{ color: "#64748B" }}>1 Brand Template</li>
                            <li style={{ color: "#64748B" }}>40 Learning Links</li>
                            <li style={{ color: "#64748B" }}>500MB content storage</li>
                        </ul>
                        <p>Change <strong>Build</strong> subscription</p>
                        <Link style={(currentFreq==="Build_monthly") ? {opacity: "0.4"} : {}} className='optionButton' to={(currentFreq==="Build_monthly") ? '' : buildMonthlyUrl}>$49 / month</Link> or&nbsp;&nbsp;&nbsp;<Link style={(currentFreq==="Build_yearly") ? {opacity: "0.4"} : {}} className='optionButton bestValue' to={(currentFreq==="Build_yearly") ? '' : buildYearlyUrl}>$499 / year (15% discount)</Link>
                    </div>
                    <p><strong>Build Pro</strong> plan.</p>
                    <ul>
                        <li style={{ color: "#64748B" }}>Unlimited Learners</li>
                        <li style={{ color: "#64748B" }}>30 Brand Template</li>
                        <li style={{ color: "#64748B" }}>500 Learning Links</li>
                        <li style={{ color: "#64748B" }}>100GB content storage</li>
                    </ul>
                    <p>Please select which <strong>Build Pro</strong> subscription option you'd like to proceed with.</p>
                    <Link style={(currentFreq==="Build Pro_monthly") ? {opacity: "0.4"} : {}} className='optionButton' to={(currentFreq==="Build Pro_monthly") ? '' : buildProMonthlyUrl}>$299 / month</Link> or&nbsp;&nbsp;&nbsp;<Link style={(currentFreq==="Build Pro_yearly") ? {opacity: "0.4"} : {}} className='optionButton bestValue' to={(currentFreq==="Build Pro_yearly") ? '' : buildProYearlyUrl}>$2870.40 / year (a 20% discount!)</Link>
                </div>            
                <div className='controls'>
                    <Link className='cancelBtn' onClick={props.onClose}>Cancel</Link>
                </div>
            </>
        </Popup>
    );


}

export default UpgradeOptions;