import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import createStyles from '../xcl_modules/createStyles';
import { Link, useNavigate } from "react-router-dom";
import saveStepIcon from '../css/icons/icon-arrowright-drk.png';
import prevStepIcon from '../css/icons/icon-arrowleft.png';
import getTokenData from '../xcl_modules/tokenData';
import DropzoneContent from '../components/DropzoneContent';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import dropdownstyles from '../xcl_modules/dropdownstyle';
import Swal from 'sweetalert2';
import InfoContentTypes from '../components/InfoContentTypes';
import LengthIndicator from '../components/LengthIndicator';

const LinkContent = () => {

    //const [objectIdRequired, setObjectIdRequired] = useState( false );
    const [infoContentTypes, setInfoContentTypes] = useState(false);
    const [fileTypeName, setFileTypeName] = useState("");
    const [types] = useState([{name:'xAPI-Published Content',val:'xAPI'}, 
        {name:'Weblink',val:'WEBSITE'},
        {name:'Image (png, jpg)',val:'IMAGE'},
        {name:'Video (mp4)',val:'VIDEO'},
        //{name:'PDF Document',val:'PDF'}
    ]);

    const [inputs, setInputs] = useState( { name: "" } );
    //const types = ['website','png','jpeg','jpg','pptx','docx','xlsx','ppt','doc','xls','mpeg','mp4','pdf'];
    /*const types = [{name:'xAPI-Published Content',val:'xAPI'}, 
        {name:'SCORM-Published Content',val:'SCORM'},
        {name:'Website',val:'website'},
        {name:'Image (png, jpg)',val:'image'},
        //{name:'Document (pptx, docx, xlsx, ppt, doc, xls, pdf)',val:'document'},
        {name:'Video (mp4)',val:'video'}
    ];*/

    const navigate = useNavigate();

    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(6);

    const getFileType = (fileType) => {
        // uses types
        types.forEach((type) => {
            if (type.val===fileType) {
                setFileTypeName(type.name);
            }
        });
    }

    useEffect(() => {
        validate();
        // get data
        // local storage key is "editLinkID"
        const getData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BE+`/link/${localStorage.getItem("editLinkID")}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`}
                });

                const result = await response.json();

                if (result.status === "success") {
                    Object.entries(result.data[0]).forEach(([key, val]) => {
                        setInputs(values => ({...values, [key]: val }));
                        if (key === "contenturl") {
                            const pathparts = val.split("/");
                            const lastLoc = pathparts.length - 1;
                            const ckey = "filename";
                            const cval = pathparts[lastLoc];
                            setInputs(values => ({...values, [ckey]: cval }));
                        }
                        if (key === "filetype") {
                            if (val==="") {
                                const defaultFileType = "website";
                                setInputs(values => ({...values, [key]: defaultFileType }));
                                types.forEach((type) => {
                                    if (type.val===defaultFileType) {
                                        setFileTypeName(type.name);
                                    }
                                });
                            } else {
                                /*if (val.toUpperCase()==="XAPI") {
                                    setObjectIdRequired(true);
                                }*/
                                types.forEach((type) => {
                                    if (type.val===val) {
                                        setFileTypeName(type.name);
                                    }
                                });
    
                            }
                        
                        }
                        
                    });
                    // add the file name
                }
                
            } catch {
                Swal.fire({
                    position: "top",
                    iconHtml:  '<div class="positoast"></div>',
                    title: "System Error :O  Please Login again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true,
                    showClass: {
                        popup: 'toastCongratsOn'
                    },
                    hideClass: {
                        popup: 'toastCongratsOff'
                    }
                });
            }
        }

        getData();
    }, [types]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        doSave();
    }

    const doSave = (navTo) => {
        const saveData = async (navTo) => {

            try {
                const response = await fetch(process.env.REACT_APP_BE+'/link', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`},
                    body: JSON.stringify( { ID: localStorage.getItem("editLinkID"), hosted: inputs.hosted.toString(), contenturl: inputs.contenturl, filetype: inputs.filetype } )
                });
                const result = await response.json();
                //console.log(result);
                if (result.status === "success" || result.code === 202) {
                    //console.log("link updated");
                    (navTo) ? navigate(navTo) : navigate("/linkpublish");
                }
            } catch {
                Swal.fire({
                    position: "top",
                    iconHtml:  '<div class="positoast"></div>',
                    title: "System Error :O  Please Login again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true,
                    showClass: {
                        popup: 'toastCongratsOn'
                    },
                    hideClass: {
                        popup: 'toastCongratsOff'
                    }
                });
            }
        }
        saveData(navTo);        
    }

    const handleChangeInt = (event) => {

        const name = event.target.name;
        const value = parseInt(event.target.value);
    
        setInputs(values => ({...values, [name]: value}));
    
    }
      
    const handlePrevNavigation = (event) => {
        event.preventDefault();
        navigate("/linkname");
    }
  
    const doFileUploaded = (name, value, typekey, type, objectId) => {
        setInputs(values => ({...values, [name]: value}));
        setInputs(values => ({...values, [typekey]: type}));
        /*if (type.toUpperCase() === "XAPI") {
            setObjectIdRequired(true);
            const okey = "xapi_activity_id";
            setInputs(values => ({...values, [okey]: objectId}));
        }*/
    }

    const getFileName = () => {
        if (inputs.contenturl!=="") {
            const pathparts = inputs.contenturl.split("/");
            const lastLoc = pathparts.length - 1;
            return pathparts[lastLoc];
        }
    }

    const doSelectType = (event) => {

        const val = event.target.id;
        const name = "filetype";                            
        setInputs(values => ({...values, [name]: val}));
        getFileType(val);

        setIsOpen(false);

        /*if (val.toUpperCase() === "XAPI") {
            setObjectIdRequired(true);
        } else {
            setObjectIdRequired(false);
        }*/

    }

    const openMenu = () => {
        // do we have room
        window.scrollBy(0, 200);
        setIsOpen(true);
    }
  
    const infoContentTypesOpen = () => {
        setInfoContentTypes(true);
      }
      
    const infoContentTypesClose = () => {
        setInfoContentTypes(false);
    }

    const gotoName = () => {
        doSave("/linkname");
    }

    const gotoPublish = () => {
        doSave("/linkpublish");
    }
  
    const gotoDash = () => {
        doSave("/xcl/links");
    }

    const handleCancel = () => {
        navigate("/xcl/links");
    }
  
    const progress = "66%";

    return (
        <>
        <Header/>
        <ScrollToTop/>
        <style>{createStyles}{dropdownstyles}</style>
        <div className='createnew header'>
            <div className='stepperheader'>
                <Link onClick={gotoDash}>&lt; Back to Dashboard</Link>
                <h3>
                Learning Link 
                </h3>
                <p>
                Make updates and edits to your learning link, when saved they will automatically update the learning link. 
                </p>
                <div className='steps'>
                  <div className='step prior'>
                      <Link onClick={gotoName}>
                      <div className='indicator' title="step 1">&#10003;</div>
                      Name and Brand Details
                      </Link>
                  </div>
                  <hr/>
                  <div className='step links active'>
                      <Link>
                      <div className='indicator' title="step 2">2</div>
                      Learning Link Content
                      </Link>
                  </div>
                  <hr/>
                  <div className='step upcoming'>
                      <Link onClick={gotoPublish}>
                      <div className='indicator' title="step 3">3</div>
                      Publish Link
                      </Link>
                  </div>
                </div>
            </div>
        </div>
        <form onSubmit={handleSubmit}>

          <div className='formContainer'>
            <h4 id="contentstarts">Add your Learning Link content </h4>
            
            <div className='radio-options'>
                <div>
                    <input type='radio' id='uploadfile' name='hosted' value='1' onChange={handleChangeInt} checked={((inputs.hosted===1||inputs.hosted==="1") ? true : false )}></input>
                    <label htmlFor="uploadfile" className='inlineLabel'>Upload File</label>
                </div>
                <div>
                    <input type='radio' id='url' name='hosted' value='0' onChange={handleChangeInt} checked={((inputs.hosted===0||inputs.hosted==="0") ? true : false )}></input>
                    <label htmlFor="url" className='inlineLabel'>URL</label>
                </div>
            </div>
            <div className={(inputs.hosted===0) ? '' : 'hideme'}>
                <label htmlFor="name">URL Address</label>
                <input type="text"
                maxLength="300" 
                value={inputs.contenturl || ""}
                name="contenturl" 
                onChange={handleChange}
                placeholder="e.g. https://www.yourcontent.com/contentmodule/start.html"></input>
            <LengthIndicator max="300" threshold="0.75" length={(inputs.contenturl) ? inputs.contenturl.length : 0} />


                <div className='ddconntainer' style={{ position: "relative" }}>
                    <label htmlFor="contentType" style={{ display:"inline-block", width:"205px" }}>What type of content is this?</label><Link className="icon-inlinesupport" onClick={infoContentTypesOpen} aria-label='Click for more information on selecting a content type for your link'></Link>
                    <InfoContentTypes 
                        open={infoContentTypes}
                        onClose={infoContentTypesClose} 
                    />
                    <Link name="contentType" className={(isOpen) ? 'ddbutton open': 'ddbutton'} {...buttonProps} onKeyDown={openMenu} onClick={openMenu}>
                        {fileTypeName}
                    </Link>
                        { (types.length > 0) ? (
                        <>
                        <div style={{ position: "absolute" }}>
                            <div className={"listMenu upDir " + (isOpen ? 'visible' : '')} role='menu' style={{ top: "  0px", left: "0px", minWidth: "456px", maxWidth:"800px" }}>
                            { types.map((type, i) => (
                                <Link {...itemProps[i]} key={"dd1"+i} id={type.val} onClick={doSelectType} className={(type.val===inputs.filetype) ? 'selected': ''}>{type.name}</Link>
                            )) }
                            </div>
                        </div>
                        </>
                        ) : ( <></> )}
                </div>
            </div>
            
            <div className={(inputs.hosted===1) ? '' : 'hideme'}>
                {
                (typeof(inputs.contenturl)!=="undefined") ? (
                    <DropzoneContent 
                    linkID={inputs.linkID}
                    contenturl={inputs.contenturl} 
                    filetype={inputs.filetype}
                    filename={getFileName}
                    onChange={doFileUploaded} 
                    storage={inputs.storage}
                    storageAvailable={inputs.storageAvailable}
                    />
                    
                ) : null
                }
            
                <input type="hidden"
                    value={inputs.contenturl || ""}
                    name="brand_logo"
                    onChange={handleChange}
                    ></input>
            
            </div>

        </div>
          
        

        <div className='formFooter'>
            <div className='progressbarbackground'>
                <div className='progressbarprogress links' style={{ width: progress }}></div>
            </div>
            <div className='stepperFormControls'>
                <input type="button" name='backone' value="Previous: Name & Brand" style={{ backgroundImage: `url('${prevStepIcon}')`}} onClick={handlePrevNavigation}></input>
                <input type="button" name='cancel' className='cancel' value="Cancel" style={{}} onClick={handleCancel}></input>
                <input type="submit" name='subitBtn' value="Next: Publish Link" style={{ backgroundImage: `url('${saveStepIcon}')`}}></input>
            </div>
        </div>
      </form>
      <div className='end' style={{ height: "500px"}}> </div>
    </>
  );
};

export default LinkContent;