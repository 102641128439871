import ScrollToTop from '../components/ScrollToTop';
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import validate from '../xcl_modules/validate';
import { useEffect, useState } from 'react';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import iconAccountProfile from '../css/icons/icon-location.png';
import iconSave from '../css/icons/icon-save.png';
import LengthIndicator from '../components/LengthIndicator';
import CountriesDropDown from '../components/CountriesDropDown';
import {countryDisplay} from '../xcl_modules/countries';
import Swal from 'sweetalert2';

const AccountBillingAddress = () => {
  const [customer, setCustomer] = useState({});
  const [card, setCard] = useState({});
  const [currentField, setCurrentField] = useState("");

  const [navOpen] = useOutletContext();

  const nav = useNavigate();


  useEffect(() => {

    validate();
    const userData = getUserData();

    const getData = async () => {
      try {
          
        const response = await fetch(process.env.REACT_APP_BE+`/billing/${userData.account}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 
              Authorization: `Bearer ${getTokenData().accessToken}`}
        });

        const result = await response.json();

        if (result.status === "success") {
            setCard(result.card);
            setCustomer(result.customer);
        }

      } catch {
        console.log("error getting data - see tools");
      }
    }

    getData();

  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCurrentField(name);
    setCard(values => ({...values, [name]: value}));
  }

  const editModeOff = () => {
    // no save
    nav(`/xcl/accountbilling`);
  }

  const saveEdits = async () => {
    // ok, so save this stuff
    try {
      
      const response = await fetch(process.env.REACT_APP_BE+`/savecardbilling`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 
          Authorization: `Bearer ${getTokenData().accessToken}`},
        body: JSON.stringify( { ...card } )
      });

      const result = await response.json();

      if (result.status === "success") {
        //console.log(result.message);
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "Your changes have been saved!",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastCongratsOn'
          },
          hideClass: {
              popup: 'toastCongratsOff'
          }
        });
      }

    } catch {
        console.log("error setting data - see tools");
    }

    nav(`/xcl/accountbilling`);

  }

  const EditableValue = (props) => {
    let name = props.name;
    let label = props.label;
    let value = props.value;
    let length = props.length;

    const switchType = () => {
      switch(name) {
        case "billing_country":
          return (
            <select name={name} defaultValue={value} onChange={handleChange}>
              <CountriesDropDown/>
            </select>
          )
        default:
          return (
            <>
              <input type="text" 
                value={value} 
                maxLength={length} 
                name={name} 
                placeholder="" 
                onChange={handleChange}
                autoFocus={(currentField === name) ? true : false}></input>
              <LengthIndicator max={length} threshold="0.75" length={(value) ? value.length : 0} />
            </>
          )
      }
    }

    const switchDisplay = () => {
      switch(name) {
        case "billing_country":
          return (
            <>
              {countryDisplay(value)}
            </>            
          )
        default:
          return (
            <>
              {value}
            </>
          )
      }
    }
    return (
      <div className="account-row">
        <label htmlFor={name}>{label}</label>
        <div className="ap-display" style={{display:"none"}}>
          { (value) ? switchDisplay(value) : "(empty)" }
        </div>
        <div className="ap-edit" style={{display:"block"}}>
          { switchType() }
        </div>
      </div>
    )
  }

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <h1 id="contentstarts">My Account</h1>
      <div className='accountsContainer'>
        <div className="account-tabs">
          <div className="account-tab">
            <Link to="/xcl/accountprofile" className="at-label">Account Settings</Link>
          </div>
          <div className="account-tab">
            <Link to="/xcl/accountpassword" className="at-label">Security & Password</Link>
          </div>
          <div className="account-tab">
            <Link to="/xcl/accountplan" className="at-label">My Plan</Link>
          </div>
          <div className="account-tab active">
            <Link to="/xcl/accountbilling" className="at-label selected">Billing History</Link>
          </div>
        </div>
        <div className="account-tiles" style={{ borderRadius: "0px 8px 8px 8px" }}>
          <div className="account-tile" style={(card.card_type) ? {display:"block"} : {display:"none"}}>
            <h4 style={{ marginBottom: "36px" }}>
              <img src={iconAccountProfile} alt="Billing address"/> 
              Billing Address
              <div className="account-controls">
              <input type="button" name='profileEdit' value="Cancel" onClick={editModeOff} style={{ display:"inline" }}></input>
              <input type="button" name='profileEdit' value="Save Changes " className="ap-action-button" onClick={saveEdits} style={{ display:"inline", backgroundImage: `url('${iconSave}')`}}></input>
              </div>
            </h4>
            
            <div className="account-fields">
                <EditableValue name="first_name" label="First Name" value={card.first_name || customer.first_name || ""} length="120" />
                <EditableValue name="last_name" label="Last Name" value={card.last_name || customer.last_name || ""} length="120" />
                <EditableValue name="billing_addr1" label="Street Address" value={card.billing_addr1 || ""} length="100" />
                <EditableValue name="billing_addr2" label="Address 2" value={card.billing_addr2 || ""} length="100" />
                <EditableValue name="billing_city" label="City" value={card.billing_city || ""} length="60" />
                <EditableValue name="billing_zip" label="Postal Code" value={card.billing_zip || ""} length="10" />
                <EditableValue name="billing_country" label="Country" value={card.billing_country || ""} length="45" />
                <EditableValue name="billing_state" label="State" value={card.billing_state || ""} length="45" />
            </div>            

          </div>
        </div>

      </div>

    </div>
  );
};

export default AccountBillingAddress;