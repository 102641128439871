import { useEffect } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import accountStyle from '../xcl_modules/accountStyle';
import dropdownstyles from '../xcl_modules/dropdownstyle';
import { useOutletContext } from "react-router-dom";

import { List } from '../components/List';
import validate from '../xcl_modules/validate';
import adminOnly from '../xcl_modules/adminOnly';

const AdminAccounts = () => {

    const [navOpen] = useOutletContext();

    useEffect(() => {
        validate();
    }, []);

    if (adminOnly) {
        return (
            <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
                <style>{accountStyle}{dropdownstyles}</style>
                <ScrollToTop/>
                <h1 id="contentstarts">
                    Accounts 
                </h1>
                <h4>
                    View XCL accounts here.  This is a view only list, Chargebee subscription and customer information is the record of truth.
                </h4>
                <List 
                    table='accounts' 
                    showkeys='["ID", "fullname", "username", "company" ,"plan", "created","hasSubsId"]' 
                    keylabels='["ID", "Name", "Username", "Company", "Plan", "Created On","CB"]' 
                    sortablekeys='["name","company","plan","created"]' />
            </div>
        )
    } else {
        document.location.href = "/login";
        return <div>no access</div>
    }
};

export default AdminAccounts;