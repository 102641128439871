import { useState, useEffect } from 'react';
import listStyle from '../xcl_modules/listStyle';
//import menuDots from '../css/icons/icon-menudots.png';
//import listSortaz from '../css/icons/icon-listsort-az.png';
//import listSortza from '../css/icons/icon-listsort-za.png';
import openBox from '../img/openBox.png';
import getTokenData from '../xcl_modules/tokenData';
import { Link } from "react-router-dom";

//const menuDotsAlt = "Open the context menu for this item";
//const listSortazAlt = "Column sorted in ascending order, click to change";
//const listSortzaAlt = "Column sorted in descending order, click to change";
const openBoxAlt = "Empty box";

const startData = [
    {
        ID: 100,
        item1: "|||",
        item2: "|||",
        item3: "|||",
        item4: "|||",
        item5: "|||",
        item6: "|||"
    },
    {
        ID: 200,
        item1: "|||",
        item2: "|||",
        item3: "|||",
        item4: "|||",
        item5: "|||",
        item6: "|||"
    },
    {
        ID: 300,
        item1: "|||",
        item2: "|||",
        item3: "|||",
        item4: "|||",
        item5: "|||",
        item6: "|||"
    }
]
const startShowkeys = '["item1","item2","item3","item4","item5","item6"]';
// settings
//const itemsPerPage = 6;

/*const SortableIcon = (props) => {
    const direction = props.dir;

    if (direction === "za") {
        return (
            <img src={listSortza} alt={listSortzaAlt} key={props.icoKey} />
        );
    } else {
        return (
            <img src={listSortaz} alt={listSortazAlt} key={props.icoKey} />
        );
    } 
}*/


export const List = (props) => {
    const [data, setData] = useState( [] );
    const [hookedData, setHookedData] = useState( startData );
    const [activeAccounts, setActiveAccounts] = useState( [] );
    const [showAll, setShowAll] = useState( false );
    const [showkeys, setShowkeys] = useState( startShowkeys );
    // map rows
    const tableHeaders = JSON.parse(props.keylabels);
    //const sortablekeys = JSON.parse(props.sortablekeys);
    // empty data
    //props.emptyheading;
    //props.emptyinstruction;
    //props.linklabel
    //props.linkto
    
    // get table from api
    
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BE+'/'+props.table, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`}
                });
                const result = await response.json();
                setShowkeys(props.showkeys);
                if (result.code === 202) {
                    // there are no templates :(
                    result.list = [ { ID:0, listEmpty: "message" } ];
                }
                //console.log(result.list);
                setData(result.list);
                
                let activeList = [];
                result.list.forEach((account) => {
                    if (account.status===1) {
                        activeList.push(account);
                    }
                });
                setActiveAccounts(activeList);
                setHookedData(activeList);
            } catch {
                console.log("error getting data - log in again");
            }
        }
        getData();
    }, [props]);

    const doShowAll = (e) => {
        //console.log(e.target.checked);
        setHookedData((e.target.checked) ? data : activeAccounts)
        setShowAll((e.target.checked) ? true : false);
    }

    let currentShowKeys = JSON.parse(showkeys);

    return (
        <>
            <style>{listStyle}</style>
            <h5 style={{ marginBottom: "16px" }}>Total Accounts: {data.length}<br/>Total Active: {activeAccounts.length}</h5>
            <p>
                <input name="showall" type="checkbox" onChange={doShowAll} style={{ marginTop: "0px" }} checked={showAll} />
                <label htmlFor="showall" style={{ verticalAlign: "super", display: "inline" }}> Show all accounts</label>
            </p>
            <table className='listtable admin'>
                <tbody>
                    <tr className='header'>
                        { tableHeaders.map((header, i) => 
                            <th key={`head_${i}`}>{header}
                            { /*sortablekeys.includes(currentShowKeys[i]) ? (
                                <SortableIcon dir="az" icoKey={'icon_'+i} />
                            ) : ( <></> )*/ }
                            </th>
                        ) }
                        <th></th>
                    </tr>
                    { (hookedData[0].listEmpty) ? (
                        <tr>
                            <td colSpan={currentShowKeys.length} className='stophover'>
                                <div className='emptyTable'>
                                    <img src={openBox} alt={openBoxAlt}/>
                                    <h3>{props.emptyheading}</h3>
                                    <p>{props.emptyinstruction}</p>
                                    <Link to={props.linkto}>
                                        {props.linklabel}
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ) : hookedData.map((rowData, i) =>
                        <Row 
                          key={'row_'+i} 
                          rowKey={rowData.ID+'_'+i} 
                          index={i} 
                          showkeys={showkeys} 
                          rowID={rowData.ID}
                          data={JSON.stringify(rowData)} />
                    )}
                </tbody>
            </table>
        </>
    )
}

const Row = (props) => {
    // map to cols
    const rowid = props.id;
    const showkeys = JSON.parse(props.showkeys);
    const rowKey = props.rowKey;
    const theData = JSON.parse(props.data);


    const thisData = Object.keys(theData)
    .filter(key => showkeys.includes(key))
    .reduce((obj, key) => {
        obj[key] = theData[key];
        return obj;
    }, {});
    
    const verboseStatus = () => {
        let s = "one moment...";
        switch (theData.status) {
            case 1:
                s = "active";
                break;
            case 2: 
                s = "inactive";
                break;
            case 3:
                s = "suspended";
                break;
            case 4:
                s = "deleted";
                break;
            default:
                s = "unknown";
        }
        return s;
    }
    const theStatus = verboseStatus();
    /*showkeys.forEach((key, i) => {
        console.log(key + ":" + thisData[key]);
    });*/
    
    return (
        <tr key={rowKey+'_tr'} className="listRow">
            { showkeys.map((key, i) =>
                <Cell key={'cell_'+i} cellKey={rowKey+"_"+i} rowid={rowid} data={thisData[key]} datakey={key} />
            )}
            <td className='listmenu' key={rowKey+'_td2'}>
                {theStatus}
            </td>
        </tr>
    )
}

const Cell = (props) => {

    let data = props.data;
    const datakey = props.datakey;
    const cellKey = props.cellKey;
    
    // dates
    if (datakey.toLowerCase().indexOf("date") > -1 || datakey.toLowerCase().indexOf("created") > -1 || datakey.toLowerCase().indexOf("modified") > -1) {
        const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        data = new Date(data).toLocaleDateString('en-US',dateOptions);
    }
    // logos
    if (datakey.toLowerCase().indexOf("brand_logo") > -1) {
        const imgSrc = data;
        data = `<img src="${imgSrc}" alt="brand logo" style="height:60px" />`;
    }


    if (data === "|||") {
        // start state lazy loading box
        data = (
            <div style={{ backgroundColor: "#EEEEEE", borderRadius: "6px", height: "1em" }}/>
        )
    }

    return (
        <td key={cellKey} className={`listcell ${datakey}`}>
            { data ? data : `-` }
        </td>
    )
}
