import { useEffect } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import accountStyle from '../xcl_modules/accountStyle';
import dropdownstyles from '../xcl_modules/dropdownstyle';
import { useOutletContext } from "react-router-dom";

import { ListAdmin } from '../components/ListAdmin';
import validate from '../xcl_modules/validate';
import adminOnly from '../xcl_modules/adminOnly';

const AdminAccounts = () => {

    const [navOpen] = useOutletContext();

    useEffect(() => {
        validate();
    }, []);

    if (adminOnly) {
        return (
            <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
                <style>{accountStyle}{dropdownstyles}</style>
                <ScrollToTop/>
                <h1 id="contentstarts">
                    Accounts 
                </h1>
                <h4>
                    Special View for pruning accounts that are cancelled.
                </h4>
                <div style={{ color:"red", margin:"0px 0px 36px 0px" }}>Warning!  These changes will be <strong>immediate and permanent</strong>, please back up files and export LRS data before proceeding.</div>
                <ListAdmin 
                    table='accounts' 
                    showkeys='["ID", "username", "company","accountID","cb_cust_id","cb_subs_id"]' 
                    keylabels='["ID", "Username", "Company","Account ID", "cust", "sub"]' 
                    sortablekeys='["company"]' />
            </div>
        )
    } else {
        document.location.href = "/login";
        return <div>no access</div>
    }
};

export default AdminAccounts;