import ScrollToTop from '../components/ScrollToTop';
import linksStyle from '../xcl_modules/linksStyle';
import dropdownstyles from '../xcl_modules/dropdownstyle';
import { List } from '../components/ListProfiles';
import validate from '../xcl_modules/validate';
import brandHorn from '../css/icons/icon-horn.png';
import brandHornButtonGraphic from '../css/icons/icon-horn-btn.png';
import brandHornButtonGraphicDisabled from '../css/icons/icon-horn-wire.png';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from "react-router-dom";

const brandHornAlt = "Brand icon";

const Profiles = (props) => {

  const [cap, setCap] = useState( 1 );
  const [len, setLen] = useState( 0 );

  const [navOpen] = useOutletContext();

  const [ready, setReady] = useState( false );

  const location = useNavigate();

  useEffect(() => {
    validate();
  }, []);

  const doSelectRow = (rowID) => {
    localStorage.setItem("editProfileID", rowID);
    location("/profilename");
  } 

  const doSetCapAndLen = (thisCap, thisLen) => {
    setCap(thisCap);
    setLen(thisLen);
    setReady( true );
  }

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <style>{linksStyle}{dropdownstyles}</style>
      <ScrollToTop/>
      <h1 id="contentstarts">
        <img src={brandHorn} alt={brandHornAlt} className='iconInline' />
        Brand Templates
      </h1>
      <Link to={((cap<=len) ? '' : '/profilenew')} className={'createNewBtn profiles '+((cap<=len) ? 'disabled' : '')} style={(!ready) ? { display: "none" } : { backgroundImage: (cap<=len) ? `url('${brandHornButtonGraphicDisabled}')` : `url('${brandHornButtonGraphic}')` }}>     
        {(cap<=len) ? (
          <>
            You've reached your Brand Template limit
          </>          
        ) : (
          <>
            Create a New Brand Template
          </>
        )}
      </Link>
      <h4>
        Create a brand template and apply it to your learning links for easy set up. 
      </h4>
      <List 
        table='linkprofiles' 
        showkeys='["brand_logo","name","lrs_xcl","created","modified"]' 
        tableHeaders='["Template Logo","Template Name","XCL LRS","Created On","Last Modified"]'
        sortablekeys='["name","created","modified"]'
        sortablekeyDirs='["","az","","za","za"]'
        emptyheading="You haven't created a Brand Template yet!"
        emptyinstruction="Brand Templates contain LRS details, logo and background color, and custom fields for your content's landing page. Create a Brand Template to apply to your Learning Links."
        linklabel="Create a Brand Template"
        linkto="/profilenew"
        setCapAndLen={ doSetCapAndLen }
        doSelectRow={ doSelectRow }
        />
    </div>
  );
};

export default Profiles;