import ScrollToTop from '../components/ScrollToTop';
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import validate from '../xcl_modules/validate';
import { useEffect, useState } from 'react';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import iconSave from '../css/icons/icon-save.png';
import LengthIndicator from '../components/LengthIndicator';
import CountriesDropDown from '../components/CountriesDropDown';
import MonthsDropDown from '../components/MonthsDropDown';
import YearsDropDown from '../components/YearsDropDown';
import {countryDisplay} from '../xcl_modules/countries';
import Swal from 'sweetalert2';

const AccountBillingCard = () => {
  const [card, setCard] = useState({});
  const [currentField, setCurrentField] = useState("");

  const [navOpen] = useOutletContext();

  const nav = useNavigate();


  useEffect(() => {

    validate();
    const userData = getUserData();

    const getData = async () => {
      try {
          
        const response = await fetch(process.env.REACT_APP_BE+`/billing/${userData.account}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 
              Authorization: `Bearer ${getTokenData().accessToken}`}
        });

        const result = await response.json();

        if (result.status === "success") {
            setCard(result.card);
        }

      } catch {
        console.log("error getting data - see tools");
      }
    }

    getData();

  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCurrentField(name);
    setCard(values => ({...values, [name]: value}));
  }

  const editModeOff = () => {
    // no save
    nav(`/xcl/accountbilling`);
  }

  const saveEdits = async () => {
    // ok, so save this stuff
    try {
      
      const response = await fetch(process.env.REACT_APP_BE+`/savecard`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 
          Authorization: `Bearer ${getTokenData().accessToken}`},
        body: JSON.stringify( { ...card } )
      });

      const result = await response.json();

      if (result.status === "success") {
        //console.log(result.message);
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "Your changes have been saved!",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastCongratsOn'
          },
          hideClass: {
              popup: 'toastCongratsOff'
          }
        });
      }

    } catch {
        console.log("error setting data - see tools");
    }

    nav(`/xcl/accountbilling`);

  }

  const EditableValue = (props) => {
    let name = props.name;
    let label = props.label;
    let value = props.value;
    let length = props.length;

    const switchType = () => {
      switch(name) {
        case "billing_country":
          return (
            <select name={name} defaultValue={value} onChange={handleChange}>
              <CountriesDropDown/>
            </select>
          )
          case "expiry_month":
            return (
              <select name={name} defaultValue={value} onChange={handleChange}>
                <MonthsDropDown/>
              </select>
            )
          case "expiry_year":
            return (
              <select name={name} defaultValue={value} onChange={handleChange}>
                <YearsDropDown/>
              </select>
            )
          default:
          return (
            <>
              <input type="text" 
                value={value} 
                maxLength={length} 
                name={name} 
                placeholder="" 
                onChange={handleChange}
                autoFocus={(currentField === name) ? true : false}></input>
              <LengthIndicator max={length} threshold="0.75" length={(value) ? value.length : 0} />
            </>
          )
      }
    }

    const switchDisplay = () => {
      switch(name) {
        case "billing_country":
          return (
            <>
              {countryDisplay(value)}
            </>            
          )
        default:
          return (
            <>
              {value}
            </>
          )
      }
    }
    return (
      <div className="account-row">
        <label htmlFor={name}>{label}</label>
        <div className="ap-display" style={{display:"none"}}>
          { (value) ? switchDisplay(value) : "(empty)" }
        </div>
        <div className="ap-edit" style={{display:"block"}}>
          { switchType() }
        </div>
      </div>
    )
  }

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <h1 id="contentstarts">My Account</h1>
      <div className='accountsContainer'>
        <div className="account-tabs">
          <div className="account-tab">
            <Link to="/xcl/accountprofile" className="at-label">Account Settings</Link>
          </div>
          <div className="account-tab">
            <Link to="/xcl/accountpassword" className="at-label">Security & Password</Link>
          </div>
          <div className="account-tab">
            <Link to="/xcl/accountplan" className="at-label">My Plan</Link>
          </div>
          <div className="account-tab active">
            <Link to="/xcl/accountbilling" className="at-label selected">Billing History</Link>
          </div>
        </div>
        <div className="account-tiles" style={{ borderRadius: "0px 8px 8px 8px" }}>
          <div className="account-tile" style={(card.card_type) ? {display:"block"} : {display:"none"}}>
            <h4 style={{ marginBottom: "36px" }}>
              <div className='acBillingImage' alt={`${card.card_type} card`} style={{ backgroundImage: "url('../img/ccards/dark/"+card.card_type+".png')", width: "33px", height:"21px", display: "inline-block", margin: "0px 8px -3px 0px"}}></div> 
              Card Details
              <div className="account-controls">
              <input type="button" name='profileEdit' value="Cancel" onClick={editModeOff} style={{ display:"inline" }}></input>
              <input type="button" name='profileEdit' value="Save Changes " className="ap-action-button" onClick={saveEdits} style={{ display:"inline", backgroundImage: `url('${iconSave}')`}}></input>
              </div>
            </h4>
            
            <div>
              <div className='acBillingImage' alt={`${card.card_type} card`} style={{ backgroundImage: "url('../img/ccards/dark/"+card.card_type+".png')", width: "80px", height:"50px"}}></div> 
              <p><span style={{ textTransform:"uppercase", fontWeight: "700" }}>{card.card_type}</span> {card.masked_number}</p>
              <p>EXPIRATION: {card.expiry_month} / {card.expiry_year}</p>
            </div>

            <div className="account-fields">
                <EditableValue name="expiry_month" label="New Expiry Month" value={card.expiry_month || ""} length="120" />
                <EditableValue name="expiry_year" label="New Expiry Year" value={card.expiry_year || ""} length="120" />
            </div>            

          </div>
        </div>

      </div>

    </div>
  );
};

export default AccountBillingCard;