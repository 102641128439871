import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom";
import iconModalSuccess from '../img/icon-email-green.png';


const ConfirmPasswordChange = (props) => {
    
    return (
        <Popup open={props.open} closeOnDocumentClick onClose={props.onClose} className='xclPop'>
            <div>
                <div className='emailIcon' style={{ backgroundImage: `url('${iconModalSuccess}')` }}></div>
                <div className='modalContent success centered'>
                    <h2 className='success'>Forgot Password</h2>
                    <p>An email has been sent to reset your password.
                    Please check your email and follow the instructions.</p>
                    <p>If you don’t see an email be sure to check your spam or junk folder.</p>
                </div>            
                <div className='controls'>
                    <Link className='closeBtn' onClick={props.onClose}>X Close</Link>
                </div>
            </div>
        </Popup>
    );


}

export default ConfirmPasswordChange;