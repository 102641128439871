import ScrollToTop from '../components/ScrollToTop';
import { Link, useOutletContext, useNavigate } from "react-router-dom";
import validate from '../xcl_modules/validate';
import { useEffect, useState } from 'react';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import Swal from 'sweetalert2';

const AccountBilling = () => {
    const [customer, setCustomer] = useState({});
    const [card, setCard] = useState({});
    const [subscription, setSubscription] = useState({});
    const [invlist, setInvlist] = useState([]);

    const [navOpen] = useOutletContext();

    const nav = useNavigate();

    useEffect(() => {

        validate();

        // new endpoint for getting single sub information
        const userData = getUserData();

        const getData = async () => {
            try {
          
                const response = await fetch(process.env.REACT_APP_BE+`/billing/${userData.account}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                      Authorization: `Bearer ${getTokenData().accessToken}`}
                });
        
                const result = await response.json();
        
                if (result.status === "success") {
                    setCustomer(result.customer);
                    if (result.customer.card_status !== "no_card") {
                        setCard(result.card);
                        console.log("no card");
                    }
                    setInvlist(result.invlist);
                    setSubscription(result.subscription);
                    //setPlan(result.plan);
                    //console.log(result);
                }
        
            } catch {
                console.log("error getting data - see tools");
            }
        }
        
        getData();

    }, []);

    const doCardEdit = () => {

        nav("/xcl/accountbillingcard");

    }

    const doBillingAddressEdit = () => {
        
        //nav("/xcl/accountprofile?r=accountbilling&m=edit");
        nav("/xcl/accountbillingaddress");

    }

    const doGetInvoice = async (e) => {
        const thisInvoiceID = e.target.id;
        console.log("get invoice PDF for id " + thisInvoiceID);
        try {
          
            const response = await fetch(process.env.REACT_APP_BE+`/invoice/${thisInvoiceID}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json', 
                  Authorization: `Bearer ${getTokenData().accessToken}`}
            });
    
            const result = await response.json();
    
            if (result.status === "success") {
                
                const link = document.getElementById("downloader");
                
                if (link) {
                    //console.log(result.download.download_url);
                    link.setAttribute('download',`Build_Capable_XCL_Invoice_${thisInvoiceID}.pdf`); // this won't do anything
                    link.href = result.download.download_url;
                    link.click();
                    // reset the link
                    link.setAttribute('download','');
                    link.href = ".";
                    
                } else {

                    console.log("error setting up link for download");
                
                }


            }
    
        } catch {
            console.log("error getting data - see tools");
        }
    }

    const doCancelPendingCancellation = async () => {
        //console.log("cancel pending cancellation");
        try {
          
            const response = await fetch(process.env.REACT_APP_BE+`/undoscheduledcancellation`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 
                Authorization: `Bearer ${getTokenData().accessToken}`},
                body: JSON.stringify( { subscriptionID: subscription.id } )
        });
    
        const result = await response.json();
    
        if (result.status === "success") {
            //console.log(result.message);
            setSubscription(result.sub);
            if (result.sub.status) {
              if (result.sub.status === "active" || result.sub.status === "in_trial") {
                Swal.fire({
                  position: "top",
                  iconHtml:  '<div class="positoast"></div>',
                  title: "Your subscription is active once again!",
                  showConfirmButton: false,
                  timer: 1500,
                  toast: true,
                  timerProgressBar: true,
                  showClass: {
                      popup: 'toastCongratsOn'
                  },
                  hideClass: {
                      popup: 'toastCongratsOff'
                  }
                });
            } else {
                Swal.fire({
                  position: "top",
                  iconHtml:  '<div class="correctitoast"></div>',
                  title: "Sadly, we couldn't undo the scheduled cancellation, please contact Build Capable using the <strong>Help and Support</strong> screen",
                  showConfirmButton: false,
                  timer: 5000,
                  toast: true,
                  timerProgressBar: true,
                  showClass: {
                      popup: 'toastWarningOn'
                    },
                  hideClass: {
                      popup: 'toastWarningOff'
                  }
                });
              }
            }
    
        } else {
            Swal.fire({
              position: "top",
              iconHtml:  '<div class="correctitoast"></div>',
              title: "Sadly, we couldn't undo the scheduled cancellation, please contact Build Capable using the <strong>Help and Support</strong> screen",
              showConfirmButton: false,
              timer: 5000,
              toast: true,
              timerProgressBar: true,
              showClass: {
                  popup: 'toastWarningOn'
                },
              hideClass: {
                  popup: 'toastWarningOff'
              }
            });
          }
    
        } catch {
            console.log("error setting data - see tools");
        }
    }

    const upcomingInvoiceAmount = (subscription.mrr) ? (subscription.mrr / 100) : 0;
    const upcomingInvoiceDateUTC= (subscription.next_billing_at) ? subscription.next_billing_at : 0;
    const upcomingInvoiceDate   = new Date(upcomingInvoiceDateUTC * 1000).toLocaleDateString();

    const cardtype              = (card.card_type) ? card.card_type : "";
    const cardNumber            = (card.masked_number) ? card.masked_number : "";
    const cardExpMonth          = (card.expiry_month) ? card.expiry_month : "";
    const cardExpYear           = (card.expiry_year) ? card.expiry_year : "";

    const billAddr1             = (card.billing_addr1) ? card.billing_addr1 : "";
    const billAddr2             = (card.billing_addr2) ? card.billing_addr2 : "";
    const billAddrCity          = (card.billing_city) ? card.billing_city : "";
    const billAddrState         = (card.billing_state) ? card.billing_state : "";
    const billAddrCountry       = (card.billing_country) ? card.billing_country : "";
    const billAddrPostalCode    = (card.billing_zip) ? card.billing_zip : "";

    const columnDate            = "20%";
    const columnInvNum          = "15%";
    const columnPlan            = "30%";
    const columnAmt             = "20%";
    const columnControl         = "15%";
  
    return (
        <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
        <ScrollToTop/>
        <h1 id="contentstarts">My Account</h1>
        <div className='accountsContainer'>
            <div className="account-tabs">
            <div className="account-tab">
                <Link to="/xcl/accountprofile" className="at-label">Account Settings</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountpassword" className="at-label">Security & Password</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountplan" className="at-label">My Plan</Link>
            </div>
            <div className="account-tab active">
                <Link to="/xcl/accountbilling" className="at-label selected">Billing History</Link>
            </div>
            </div>
            <div className="account-tiles" style={{ borderRadius: "0px 8px 8px 8px" }}>

                <div className="account-tile" style={(customer.id) ? { padding: "0px",display:"block"} : {display:"none"}} >
                    <h4  style={{ margin: "24px 40px 0px 40px"}}>Billing Details</h4>
                    <div className='accountDashBlock'>
                        <h5 style={{ fontSize: "1em", margin: "34px 40px 0px 40px" }}>Upcoming Invoice</h5>
                        {(subscription.status === "in_trial" || subscription.status === "active") ? (
                            <>
                                <div className='accountDashContent' style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", margin: "16px 40px 0px 40px" }}>
                                    <div className="upcoming-date">Date: {upcomingInvoiceDate}</div>
                                    <div className="upcoming-total">Total: ${upcomingInvoiceAmount}</div>
                                </div>
                            </>
                        ) : (
                            <>
                                { (subscription.status === "non_renewing") ? (
                                    <div className='accountDashContent warning' style={{margin: "16px 40px 0px 40px"}}>
                                        <p><strong>Cancellation pending</strong>. Your subscription was cancelled on {new Date(subscription.cancel_schedule_created_at * 1000).toLocaleDateString()}. You can keep using XCL until {new Date(subscription.cancelled_at * 1000).toLocaleDateString()}.</p>
                                        <p>If you would like to restore your subscription, please cancel the pending cancellation.</p>
                                        <p><input type="button" value="Cancel Pending Cancellation" onClick={doCancelPendingCancellation} /></p>
                                    </div>
                                ) : (
                                    <div className='accountDashContent warning' style={{margin: "16px 40px 0px 40px"}}>
                                        <p><strong>Subscription Cancelled</strong>. Your subscription was cancelled on {new Date(subscription.cancel_schedule_created_at * 1000).toLocaleDateString()}. 
                                        You cannot use any features of XCL and your XCL Links will not function. If you would like to reactivate your subscription, please contact Build Capable using the <strong>Help and Support</strong> screen.</p>
                                        <p>Your account, data, and files will be deleted on {new Date((subscription.cancel_schedule_created_at + 2592000) * 1000).toLocaleDateString()}.</p>
                                    </div>
                                )}
                            </>
                        )}

                        <div className='account-side-by-side' style={(cardtype==="") ? {display:"none"} : {display:"flex"}}>
                            <div className='account-side-by-side-side'>
                                <h5 style={{ fontSize: "1em" }}>Payment Method</h5>
                                <div className='accountDashContent' style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{textTransform:"uppercase", display: "flex", justifyContent: "space-between", gap: "8px", flexDirection: "row", flexWrap: "wrap", alignContent: "center"}}>
                                        <div className='acBillingImage' style={{ backgroundImage: "url('../img/ccards/dark/"+cardtype+".png')", width: "60px", height:"40px"}}></div>
                                        <div><strong>{cardtype}</strong> {cardNumber}<br/>EXPIRATION: {cardExpMonth}/{cardExpYear}</div>
                                    </div>
                                    <input type="button" value="Edit" className='acBillingEdit' onClick={doCardEdit} />
                                </div>
                            </div>
                            <div className='account-side-by-side-side'>
                                <h5 style={{ fontSize: "1em" }}>Billing Address</h5>
                                <div className='accountDashContent' style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", alignContent: "center" }}>
                                    <div className='acBillingImage' style={{ width: "25px", height:"25px",margin: "7px 8px 0px 0px"}}></div>
                                    <div>{billAddr1}{billAddr2}, {billAddrCity},<br/>{billAddrState}, {billAddrCountry}, {billAddrPostalCode}</div>
                                </div>
                                <input type="button" value="Edit" className='acBillingEdit' onClick={doBillingAddressEdit} />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="account-tile" style={(invlist.length) ? { padding: "0px",overflow:"hidden", backgroundColor: "#FBFBFB",display:"block"} : {display:"none"}}>
                    <div style={{minHeight: "36px", padding: "8px 16px", color:"#ffffff", backgroundColor:"#475569",display: "flex", alignItems: "center", fontSize: "0.825em"}}>
                        <div className='acListItem date' style={{minWidth: columnDate}}>Date</div>
                        <div className='acListItem invoicenum' style={{minWidth: columnInvNum}}>Invoice No.</div>
                        <div className='acListItem plan' style={{minWidth: columnPlan}}>Plan</div>
                        <div className='acListItem amount' style={{minWidth: columnAmt}}>Amount</div>
                    </div>
                    
                    {(invlist.length) ? invlist.map((listitem, i) => (
                        <div key={"inv"+i} style={{minHeight: "70px", padding: "8px 16px", color:"#353E5A", backgroundColor:"#FFFFFF",display: "flex", alignItems: "center", marginBottom:"16px" }}>
                            <div className='acListItem date' style={{minWidth: columnDate}}>{new Date(listitem.invoice.date*1000).toLocaleString().split(",")[0]}</div>
                            <div className='acListItem invoicenum' style={{minWidth: columnInvNum}}>{listitem.invoice.id}</div>
                            <div className='acListItem plan' style={{minWidth: columnPlan}}>{listitem.invoice.line_items[0].description}</div>
                            <div className='acListItem amount' style={{minWidth: columnAmt}}>{listitem.invoice.currency_code} ${listitem.invoice.total/100}</div>
                            <div className='acListItem controls' style={{minWidth: columnControl, textAlign:"right"}}>
                                <input type="button" value="Download" id={listitem.invoice.id} onClick={doGetInvoice} />
                            </div>
                        </div>
                    )) : (
                        <div style={{margin:"36px auto", textAlign: "center" }}>no invoices available yet</div>
                    )}

                    <a href="." id="downloader" aria-hidden="true" download="Build_Capable_Invoice_.pdf"><span style={{ display:"none" }}>download</span></a>

                </div>

                <div className="account-tile" style={(subscription.status==="not found") ? { padding: "0px",display:"block" } : { display:"none" }} >
                    <div style={{ padding:"40px", textAlign:"center" }}>
                        There are no invoices associated to your account.
                    </div>
                </div>

            </div>

        </div>

        </div>
    );
};

export default AccountBilling;